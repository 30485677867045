












































import { defineComponent } from "@vue/composition-api";
import { APPLICATION_VERSION } from "@/lib/constants/env";
import { useCurrentGym } from "@/lib/hooks/useCurrentGym";
import { useGyms } from "@/lib/query/hooks/useGyms";

export default defineComponent({
	setup() {
		const { id: gymId } = useCurrentGym();
		const { data: gyms } = useGyms();

		return { APPLICATION_VERSION, gymId, gyms };
	},
});
